import { Close } from "@mui/icons-material";
import { FilterAlt } from "@mui/icons-material";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { DropdownFilter } from "@pimo/pimo-components";
import type { ManageOEFilterData, Region } from "crq-types";
import type { FC } from "react";

export type ManageOEFilterDialogProps = {
  filterData: ManageOEFilterData;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  names: string[];
  onClick?: () => void;
  regions: Region["regionName"][];
};

export const ManageOEFilterDialog: FC<ManageOEFilterDialogProps> = ({
  handleChange,
  filterData,
  names,
  onClick,
  regions,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        gap: 0,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
            pr: 1,
          }}
        >
          <FilterAlt
            sx={{ color: theme.palette.primary.main, height: "35px" }}
          />
          <Typography sx={{ fontWeight: 500 }}>Filter</Typography>
        </Box>
        <Close onClick={onClick} />
      </Box>
      <FormControl
        component="fieldset"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        {names?.length || regions?.length ? (
          <>
            {names.length ? (
              <DropdownFilter
                options={names}
                value={filterData.names}
                label="OE name"
                handleChange={handleChange}
                triggerIdentifier="names"
              />
            ) : null}
            {regions.length ? (
              <DropdownFilter
                options={regions}
                value={filterData.regions}
                label="OE Cluster"
                handleChange={handleChange}
                triggerIdentifier="regions"
              />
            ) : null}
          </>
        ) : null}
      </FormControl>
    </Box>
  );
};
