import { Box, Typography } from "@mui/material";
import React from "react";

export interface InfoBoxProps {
  data: string[];
}

export const InfoBox: React.FC<InfoBoxProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Box
      style={{
        position: "fixed",
        padding: "10px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        maxWidth: "300px",
        wordWrap: "break-word",
        whiteSpace: "normal",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
        zIndex: 9999,
      }}
    >
      {data.map((name, index) => (
        <Typography
          variant="body2"
          key={index}
          sx={{
            fontSize: "0.875rem",
            paddingY: 0.5,
            paddingX: 1,
            marginY: 0.5,
          }}
        >
          {index + 1}. {name}
        </Typography>
      ))}
    </Box>
  );
};
