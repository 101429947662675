import { ManageOEEventPayload } from "crq-types";

import { STRAPI_URL } from "../env";

export function createOE(payload: ManageOEEventPayload) {
  const { name, cimid, hideFromGroupDashboard, region } = payload;

  return fetch(`${STRAPI_URL}/api/bff-oe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      name,
      cimid,
      hideFromGroupDashboard,
      region,
    }),
  });
}

export function updateOE(id: number, payload: ManageOEEventPayload) {
  const { name, cimid, hideFromGroupDashboard, region, active } = payload;

  return fetch(`${STRAPI_URL}/api/bff-oe/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      name,
      cimid,
      hideFromGroupDashboard,
      region,
      active,
    }),
  });
}

export function deleteOE(id: number) {
  return fetch(`${STRAPI_URL}/api/bff-oe/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
}
