import { TextField } from "@mui/material";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  FormFieldValidationDefinition,
} from "../form-field";

export const TEXT_FORM_FIELD_TYPE = "text" as const;

export type TextFormFieldDefinition = BaseFormFieldDefinition<
  typeof TEXT_FORM_FIELD_TYPE
> & {
  type: typeof TEXT_FORM_FIELD_TYPE;
  maxLength?: FormFieldValidationDefinition<number>;
  minLength?: FormFieldValidationDefinition<number>;
  multiline?: boolean;
  pattern?: FormFieldValidationDefinition<RegExp>;
};

export function TextFormField({
  disabled,
  errorMessage,
  maxLength,
  minLength,
  multiline,
  name,
  pattern,
  register,
  required,
}: TextFormFieldDefinition & AdditionalFormFieldProps) {
  return (
    <TextField
      {...register(name, { disabled, maxLength, minLength, pattern, required })}
      error={!!errorMessage}
      fullWidth
      helperText={errorMessage}
      multiline={multiline}
    />
  );
}
