import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { type FC, useState } from "react";

export const ErrorDialog: FC<{
  onContinue: () => void;
  onResolve?: () => void;
  open: boolean;
}> = ({ onContinue, onResolve, open }) => {
  const [showValidationErrorsDialog, setShowValidationErrorsDialog] =
    useState<boolean>(open ?? false);

  return (
    <Dialog open={!!showValidationErrorsDialog}>
      <DialogTitle>Save with Errors?</DialogTitle>
      <Divider />
      <DialogContent>
        All fields must be filled in so that the OE can be activated.
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onResolve && onResolve();
            setShowValidationErrorsDialog(false);
          }}
        >
          Resolve errors
        </Button>
        <Button
          onClick={() => {
            onContinue && onContinue();
            setShowValidationErrorsDialog(false);
          }}
          variant="contained"
        >
          SAVE and complete later
        </Button>
      </DialogActions>
    </Dialog>
  );
};
