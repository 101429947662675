import { NotificationsPlugin } from "@pimo/notifications-plugin";
import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  TwoColumnGridLayout,
  TwoColumnGridLayoutProps,
} from "@pimo/pimo-components";
import { FilterData, ManageOEEventPayload } from "crq-types";

import { CRQAppState } from "../app";
import { GeneralSettingsPage } from "../components/settings/general-settings";
import { ManageOEsSettingsPage } from "../components/settings/manage-oes-settings-page";
import { APP_ROUTES } from "../constants";
import { fetchOERegions, fetchOEs } from "../helpers/fetch-helpers";
import {
  fetchOEOverview,
  fetchProgram,
  updateProgramReportingDate,
} from "../helpers/fetch-helpers";
import { createOE, deleteOE, updateOE } from "../helpers/fetch-oe";
import {
  fetchGlobalSetting,
  updateGlobalSetting,
} from "../helpers/global-setting";

// eslint-disable-next-line
export interface SettingsPluginState {}

export class SettingsPlugin
  implements Plugin<CRQAppState, SettingsPluginState, "viewname">
{
  route?: Route<"viewname", CRQAppState>;
  private settingsView?: View<CRQAppState, TwoColumnGridLayoutProps>;
  private app?: App<CRQAppState>;

  onRegister(app: App<CRQAppState>): void {
    this.app = app;

    const entries = [
      {
        link: "/settings/manage-OEs",
        title: "Manage OEs",
        viewname: "manage-OEs",
      },
      {
        link: "/settings/notifications",
        title: "Notifications",
        viewname: "notifications",
      },
      {
        link: "/settings/notifications-scheduled",
        title: "Scheduled Notifications",
        viewname: "scheduled-notifications",
      },
      {
        link: "/settings/general",
        title: "General Settings",
        viewname: "general",
      },
    ];

    this.settingsView = app.createView({
      name: "Settings",
      layout: new TwoColumnGridLayout({
        spacing: 1,
        enablePageBreak: false,
        workspaceRoute: APP_ROUTES.overview,
        entries,
        title: "Settings",
      }),
    });

    this.buildRoute();
    this.buildManageOEsView();
    this.buildNotificationsView();
    this.buildScheduledNotificationsView();
    this.buildGeneralSettingsView();
  }

  private async fetchSettingsPage() {
    if (!this.app) {
      return;
    }

    const [globalSetting, oes, oeRegions] = await Promise.all([
      fetchGlobalSetting(),
      fetchOEs(),
      fetchOERegions(),
    ]);

    this.app?.patchAppState({
      isSidebarOpen: false,
      settings: {
        oes,
        oeRegions,
        hasActivationCheckForUpdate:
          globalSetting?.hasActivationCheckForUpdate ?? true,
      },
    });
  }

  private buildRoute() {
    if (!this.app || !this.settingsView) return;

    this.route = this.app.createRoute<"viewname">({
      path: "/settings/:viewname",
      view: this.settingsView,
    });

    this.route.on("load", async () => {
      if (!this.app) {
        return;
      }

      if (this.app.getAppState().userProfile?.role !== "admin") {
        this.app.navigate(APP_ROUTES.overview);
      }

      await this.fetchSettingsPage();
    });
  }

  private buildManageOEsView() {
    const DEFAULT_FILTER_DATA = {
      names: [],
      regions: [],
      runs: [],
      search: "",
      status: [],
    } satisfies FilterData;

    const manageOEsView = this.settingsView?.addComponent({
      component: ManageOEsSettingsPage,
      layoutProps: { viewname: "manage-OEs", xs: 12 },
    });

    manageOEsView?.mapState(
      ({
        filterDialogData,
        filterDialogOptions,
        settings: { oes, oeRegions },
      }) => {
        return {
          filterData: filterDialogData ?? DEFAULT_FILTER_DATA,
          names: filterDialogOptions?.names ?? [],
          regions: filterDialogOptions?.regions ?? [],
          title: `Manage OEs`,
          oes,
          oeRegions,
        };
      }
    );

    manageOEsView?.on("filter:clear", async () => {
      if (!this.app) {
        return;
      }

      const OEs = await fetchOEs();

      this.app.patchAppState({
        filterDialogData: { ...DEFAULT_FILTER_DATA },
        settings: {
          ...this.app.getAppState().settings,
          oes: OEs ?? [],
        },
      });
    });

    manageOEsView?.on("filter:apply", async ({ payload }) => {
      if (!this.app) {
        return;
      }

      const oeProjects = await fetchOEs(payload as FilterData);

      this.app.patchAppState({
        settings: {
          ...this.app.getAppState().settings,
          oes: oeProjects ?? [],
        },
        filterDialogData: payload as FilterData,
      });
    });

    manageOEsView?.on("oe-created", async (event) => {
      const payload = event.payload as ManageOEEventPayload;

      if (!payload || !this.app) {
        return;
      }

      await createOE(payload);
      await this.fetchSettingsPage();
    });

    manageOEsView?.on("oe-updated", async (event) => {
      const payload = event.payload as ManageOEEventPayload;

      if (!payload || !payload.id || !this.app) {
        return;
      }

      await updateOE(payload.id, payload);
      await this.fetchSettingsPage();
    });

    manageOEsView?.on("oe-deleted", async (event) => {
      const payload = event.payload as ManageOEEventPayload;

      if (!payload || !payload.id) {
        return;
      }

      await deleteOE(payload.id);
      await this.fetchSettingsPage();
    });
  }

  private buildNotificationsView() {
    if (!this.settingsView || !this.route) return;

    const notificationsPlugin = this.app?.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications", xs: 12 },
    });
  }

  private buildScheduledNotificationsView() {
    if (!this.settingsView || !this.route || !this.app) return;

    const notificationsPlugin = this.app.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications-scheduled", xs: 12 },
      notificationType: "scheduled",
    });
  }

  private buildGeneralSettingsView() {
    const generalSettingsView = this.settingsView?.addComponent({
      component: GeneralSettingsPage,
      layoutProps: { viewname: "general", xs: 12 },
    });

    generalSettingsView?.mapState((state) => {
      return {
        hasActivationCheckForUpdate:
          state.settings?.hasActivationCheckForUpdate ?? true,
        reportingDate: state.program?.reportingDate ?? "",
      };
    });

    generalSettingsView?.on("general-settings-changed", async ({ payload }) => {
      if (!payload || !this.app) return;

      await Promise.all([
        updateProgramReportingDate(payload.reportingDate),
        updateGlobalSetting({
          hasActivationCheckForUpdate: payload.hasActivationCheckForUpdate,
        }),
      ]);

      const [OEs, OEOverview, program] = await Promise.all([
        fetchOEs(),
        fetchOEOverview(),
        fetchProgram(),
      ]);

      this.app.patchAppState({
        OEs,
        OEOverview,
        program,
      });
    });
  }
}
