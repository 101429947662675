import type { App, Plugin, UniversalAppState } from "@pimo/pimo-app-builder";

import { APP_ROUTES } from "../constants";

export class LandingPagePlugin<AppState extends UniversalAppState>
  implements Plugin<AppState>
{
  onRegister(app: App<AppState>): void {
    const view = app.createView({
      name: "Group Dashboard",
    });

    const route = app.createRoute({
      path: APP_ROUTES.dashboard,
      view,
    });

    route.on("load", () => app.navigate(APP_ROUTES.overview));
  }
}
