import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { GroupDashboardFilterData } from "crq-types";

import {
  GroupDashboardTitleCardSearchBarSlot,
  GroupDashboardTitleCardSearchBarSlotProps,
} from "./group-dashboard-title-card-search-bar-slot";

export type GroupDashboardCardProps = {
  filterData: GroupDashboardFilterData;
  title: string;
};

export const GroupDashboardTitleCard: PimoReactComponent<
  GroupDashboardCardProps,
  "filter:clear" | "filter:apply",
  GroupDashboardFilterData
> = ({ filterData, fireEvent, title, ...props }) => {
  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <GroupDashboardTitleCardSearchBarSlot
            {...(props as GroupDashboardTitleCardSearchBarSlotProps)}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
            handleFilterChange={(event, trigger) => {
              const key = trigger as keyof Pick<
                GroupDashboardFilterData,
                "regions"
              >;
              const value = event.target.name;
              const checked = event.target.checked;

              fireEvent?.("filter:apply", {
                ...filterData,
                [key]: checked
                  ? [...filterData[key], value]
                  : filterData[key].filter((v) => v !== value),
              });
            }}
          />
        </Box>
      }
    />
  );
};
