import { Box, Typography } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import {
  Card,
  CardContent,
  type CardContentProps,
  type CardProps,
} from "../../molecules";

export interface RadialBarChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Chart's colors */
  colors?: string[];
  /** Chart's labels */
  labels: string[];
  /** (optional) more card specific props */
  cardProps?: CardProps;
  /** (optional) more card content specific props */
  cardContentProps?: CardContentProps;
}

export const RadialBarChartCard: PimoReactComponent<
  RadialBarChartCardProps,
  "selectionClicked",
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartContext: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any;
  }
> = ({
  colors,
  cardProps = {},
  cardContentProps = {},
  labels,
  series,
  title,
  fireEvent,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    colors,
    legend: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      theme: "light",
    },
  };

  options.chart = {
    ...(options.chart ?? {}),
    events: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataPointSelection: (event: any, chartContext: any, config: unknown) =>
        fireEvent?.("selectionClicked", { event, chartContext, config }),
      dataPointMouseEnter: (event: MouseEvent) => {
        (event?.target as HTMLInputElement).style.cursor = "pointer";
      },
    },
  };

  return (
    <Card {...cardProps} data-testid="RadialBarChartCard" title={title}>
      <CardContent {...cardContentProps}>
        <Box
          sx={{
            ".apexcharts-datalabels-group": { display: "none" },
          }}
        >
          <ReactApexChart
            className="apex-charts"
            options={options}
            series={series}
            type="radialBar"
          />
          {labels?.map((label, index) => (
            <Typography
              key={index}
              sx={{
                color: colors?.[index] ?? "inherit",
                display: "block",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {label}
            </Typography>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RadialBarChartCard;
