import {
  FileDownloadOutlined,
  Logout,
  SettingsOutlined,
} from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  DefaultOverlayEventPayload,
  type DefaultOverlayProps,
} from "@pimo/pimo-components";
import type { Profile } from "crq-types";

import { STRAPI_URL } from "../../env";

export type CRQOverlayProps = DefaultOverlayProps & {
  reportingDate?: string;
  userProfile?: Profile;
};

export type CRQOverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:settings-click";

export type CRQOverlayEventPayload =
  | DefaultOverlayEventPayload
  | { reportingDate: string };

export const CRQOverlay: PimoReactComponent<
  CRQOverlayProps,
  CRQOverlayEventNames,
  CRQOverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() =>
            (location.href = "mailto:isrm@allianz.com?subject=CRQ:")
          }
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Contact Us
        </Button>
      </Box>
    ),
    entries: [
      ...(props?.userProfile?.role === "admin"
        ? [
            {
              icon: SettingsOutlined,
              onClick: () => fireEvent?.("overlay:settings-click"),
              text: "Settings",
            },
          ]
        : []),
      ...(props?.userProfile?.role === "admin"
        ? [
            {
              text: "Download Excel Report",
              icon: FileDownloadOutlined,
              onClick: () => window.open(`${STRAPI_URL}/api/export`),
            },
          ]
        : []),
      {
        icon: Logout,
        onClick: () => fireEvent?.("overlay:logout"),
        text: "Logout",
      },
    ],
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
