import type { QuestionnaireResponse } from "crq-types";
import type { FieldError, Path } from "react-hook-form";

export type FormFieldErrors = {
  [key in Path<QuestionnaireResponse>]?: FieldError;
};

const MIN_STORED_RECORDS_ERROR = {
  type: "required",
  message: "This field's value must be more than 0.",
};

const MAX_STORED_RECORDS_ERROR = {
  type: "required",
  message:
    "This field's value cannot be bigger than the sum of PII, PCI, and PHI records.",
};

function isFieldEmpty(value: unknown): boolean {
  return value == null || value === "";
}

function parseNumberField(value: unknown): number | null {
  if (isFieldEmpty(value)) {
    return null;
  }
  const num = Number(value);
  return isNaN(num) ? null : num;
}

export function validateFormData(data: QuestionnaireResponse): FormFieldErrors {
  const errors: FormFieldErrors = {};
  if (!data) {
    return errors;
  }

  if (!data.contact) {
    errors.contact = {
      type: "required",
      message: "Contact Person is required",
    };
  }

  if (!data.urls || data.urls.length < 1) {
    errors.urls = {
      type: "min",
      message: "Please enter at least one URL",
    };
  } else if (data.urls.length > 10) {
    errors.urls = {
      type: "max",
      message: "Please enter a maximum of 10 URLs",
    };
  }

  const annualRevenue = parseNumberField(data.annualRevenue);
  if (annualRevenue == null) {
    errors.annualRevenue = {
      type: "required",
      message: "Annual revenue is required",
    };
  } else if (annualRevenue < 1) {
    errors.annualRevenue = {
      type: "min",
      message: "Annual revenue must be a positive number",
    };
  }

  if (!data.currency) {
    errors.currency = {
      type: "required",
      message: "Please select a currency",
    };
  }

  if (!data.numberOfEmployees) {
    errors.numberOfEmployees = {
      type: "required",
      message: "Please select the number of employees",
    };
  }

  if (!data.industriesOfOperation || data.industriesOfOperation.length === 0) {
    errors.industriesOfOperation = {
      type: "required",
      message: "Please select at least one industry",
    };
  }

  if (!data.countriesOfOperation || data.countriesOfOperation.length === 0) {
    errors.countriesOfOperation = {
      type: "required",
      message: "Please select at least one country",
    };
  }

  const numberOfEmployeeEndpoints = parseNumberField(
    data.numberOfEmployeeEndpoints
  );
  if (numberOfEmployeeEndpoints == null) {
    errors.numberOfEmployeeEndpoints = {
      type: "required",
      message: "Please enter the number of employee endpoints",
    };
  }

  const percProductivityEmployees = parseNumberField(
    data.percProductivityEmployees
  );
  if (percProductivityEmployees == null) {
    errors.percProductivityEmployees = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percProductivityEmployees < 0 || percProductivityEmployees > 100) {
    errors.percProductivityEmployees = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  const percIncomeEmployees = parseNumberField(data.percIncomeEmployees);
  if (percIncomeEmployees == null) {
    errors.percIncomeEmployees = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percIncomeEmployees < 0 || percIncomeEmployees > 100) {
    errors.percIncomeEmployees = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  // Infrastructure Tab Validations

  const nrPCIOnPremise = parseNumberField(data.nrPCIOnPremise);
  if (nrPCIOnPremise == null) {
    errors.nrPCIOnPremise = {
      type: "required",
      message: "Please enter the number of PCI records",
    };
  }

  const nrPIIOnPremise = parseNumberField(data.nrPIIOnPremise);
  if (nrPIIOnPremise == null) {
    errors.nrPIIOnPremise = {
      type: "required",
      message: "Please enter the number of PII records",
    };
  }

  const nrPHIOnPremise = parseNumberField(data.nrPHIOnPremise);
  if (nrPHIOnPremise == null) {
    errors.nrPHIOnPremise = {
      type: "required",
      message: "Please enter the number of PHI records",
    };
  }

  const maxRecordsOnPremise = parseNumberField(data.maxRecordsOnPremise);
  if (maxRecordsOnPremise == null) {
    errors.maxRecordsOnPremise = {
      type: "required",
      message: "Please enter the maximum number of records",
    };
  }

  const maxRecordsOnPremiseValue =
    parseNumberField(data.maxRecordsOnPremise) ?? 0;
  const nrPIIOnPremiseValue = parseNumberField(data.nrPIIOnPremise) ?? 0;
  const nrPCIOnPremiseValue = parseNumberField(data.nrPCIOnPremise) ?? 0;
  const nrPHIOnPremiseValue = parseNumberField(data.nrPHIOnPremise) ?? 0;

  if (
    !maxRecordsOnPremise &&
    nrPIIOnPremiseValue + nrPCIOnPremiseValue + nrPHIOnPremiseValue > 0
  ) {
    errors.maxRecordsOnPremise = MIN_STORED_RECORDS_ERROR;
  }

  if (
    maxRecordsOnPremiseValue >
    nrPIIOnPremiseValue + nrPCIOnPremiseValue + nrPHIOnPremiseValue
  ) {
    errors.maxRecordsOnPremise = MAX_STORED_RECORDS_ERROR;
  }

  const percProductivityOnPremise = parseNumberField(
    data.percProductivityOnPremise
  );
  if (percProductivityOnPremise == null) {
    errors.percProductivityOnPremise = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percProductivityOnPremise < 0 || percProductivityOnPremise > 100) {
    errors.percProductivityOnPremise = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  const percIncomeOnPremise = parseNumberField(data.percIncomeOnPremise);
  if (percIncomeOnPremise == null) {
    errors.percIncomeOnPremise = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percIncomeOnPremise < 0 || percIncomeOnPremise > 100) {
    errors.percIncomeOnPremise = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  // Cloud Tab Validations

  const nrPCICloud = parseNumberField(data.nrPCICloud);
  if (nrPCICloud == null) {
    errors.nrPCICloud = {
      type: "required",
      message: "Please enter the number of PCI records",
    };
  }

  const nrPIICloud = parseNumberField(data.nrPIICloud);
  if (nrPIICloud == null) {
    errors.nrPIICloud = {
      type: "required",
      message: "Please enter the number of PII records",
    };
  }

  const nrPHICloud = parseNumberField(data.nrPHICloud);
  if (nrPHICloud == null) {
    errors.nrPHICloud = {
      type: "required",
      message: "Please enter the number of PHI records",
    };
  }

  const maxRecordsCloud = parseNumberField(data.maxRecordsCloud);
  if (maxRecordsCloud == null) {
    errors.maxRecordsCloud = {
      type: "required",
      message: "Please enter the maximum number of records",
    };
  }

  const maxRecordsCloudValue = parseNumberField(data.maxRecordsCloud) ?? 0;
  const nrPIICloudValue = parseNumberField(data.nrPIICloud) ?? 0;
  const nrPCICloudValue = parseNumberField(data.nrPCICloud) ?? 0;
  const nrPHICloudValue = parseNumberField(data.nrPHICloud) ?? 0;

  if (
    !maxRecordsCloud &&
    nrPIICloudValue + nrPCICloudValue + nrPHICloudValue > 0
  ) {
    errors.maxRecordsCloud = MIN_STORED_RECORDS_ERROR;
  }

  if (
    maxRecordsCloudValue >
    nrPIICloudValue + nrPCICloudValue + nrPHICloudValue
  ) {
    errors.maxRecordsCloud = MAX_STORED_RECORDS_ERROR;
  }

  const percProductivityCloud = parseNumberField(data.percProductivityCloud);
  if (percProductivityCloud == null) {
    errors.percProductivityCloud = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percProductivityCloud < 0 || percProductivityCloud > 100) {
    errors.percProductivityCloud = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  const percIncomeCloud = parseNumberField(data.percIncomeCloud);
  if (percIncomeCloud == null) {
    errors.percIncomeCloud = {
      type: "required",
      message: "Please enter the percentage",
    };
  } else if (percIncomeCloud < 0 || percIncomeCloud > 100) {
    errors.percIncomeCloud = {
      type: "range",
      message: "Please enter a percentage between 0 and 100",
    };
  }

  // Security Profiles Tab Validations

  const outageDurationMatImpact = parseNumberField(
    data.outageDurationMatImpact
  );
  if (outageDurationMatImpact == null) {
    errors.outageDurationMatImpact = {
      type: "required",
      message: "Please enter the outage duration",
    };
  }

  const networkInterruptionRestorationTime = parseNumberField(
    data.networkInterruptionRestorationTime
  );
  if (networkInterruptionRestorationTime == null) {
    errors.networkInterruptionRestorationTime = {
      type: "required",
      message: "Please enter the restoration time",
    };
  }

  for (let i = 1; i <= 20; i++) {
    const fieldName = `CISControl${i}` as keyof QuestionnaireResponse;
    if (!data[fieldName]) {
      errors[fieldName] = {
        type: "required",
        message: "Please select a rating",
      };
    }
  }

  return errors;
}
