import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";

export type CRQQuestionnaireDialogType =
  | "cancel-with-changes"
  | "submit"
  | "submit-without-changes";
export type CRQQuestionnaireDialogProps = {
  type?: CRQQuestionnaireDialogType;
  isCheckBoxChecked: boolean;
  isOEInsideReportingDate: boolean;
};
export type CRQQuestionnaireDialogEvent = "close" | "continue";

const CRQ_QUESTIONNAIRE_DIALOG_TEXTS = {
  "cancel-with-changes": {
    title: "Close without saving?",
    content:
      "If you close the dialog without saving, all of your changes will be lost.",
  },
  "submit-without-changes": {
    checked: {
      title: "Your data has been saved.",
      content:
        "The updated input data will be used in the next quantification run. As you have confirmed that all data has been updated, the questionnaire is now marked as green for this update period.",
    },
    unchecked: {
      title: "Your data has been saved.",
      content:
        "No changes have been done, therefore the date for the last questionnaire update will not be adapted.",
    },
  },
  submit: {
    "checked-outside-reporting-date": {
      title: "Thank you for the update.",
      content:
        "Your changes have been successfully saved. The updated input will be used in the next quantification run. The date for the last questionnaire update will be updated.",
    },
    "unchecked-outside-reporting-date": {
      title: "Thank you for the update",
      content:
        "The updated input data will be used in the next qualification run.",
    },
    "unchecked-inside-reporting-date": {
      title: "Thank you for the update",
      content:
        "The updated input data will be used in the next quantification run. As you have not confirmed that all data has been updated, the questionnaire is not marked as green for this update period. This is only necessary during a current update period.",
    },
    "checked-inside-reporting-date": {
      title: "Thank you for the update.",
      content:
        "The updated input data will be used in the next quantification run. As you have confirmed that all data has been updated, the questionnaire is now marked as green for this update period.",
    },
  },
} satisfies Record<
  CRQQuestionnaireDialogType,
  | { content: string; title: string }
  | {
      "checked-outside-reporting-date": { content: string; title: string };
      "unchecked-outside-reporting-date": { content: string; title: string };
      "unchecked-inside-reporting-date": { content: string; title: string };
      "checked-inside-reporting-date": { content: string; title: string };
    }
  | {
      checked: { content: string; title: string };
      unchecked: { content: string; title: string };
    }
>;

export const CRQQuestionnaireDialog: PimoReactComponent<
  CRQQuestionnaireDialogProps,
  CRQQuestionnaireDialogEvent
> = ({ fireEvent, type, isCheckBoxChecked, isOEInsideReportingDate }) => {
  const onClose = () => {
    fireEvent?.("close");
  };
  const onContinue = () => {
    fireEvent?.("continue");
  };

  if (!type) {
    return null;
  }

  let title = "";
  let content = "";

  if (type === "submit") {
    const submitKey = isCheckBoxChecked
      ? isOEInsideReportingDate
        ? "checked-inside-reporting-date"
        : "checked-outside-reporting-date"
      : isOEInsideReportingDate
        ? "unchecked-inside-reporting-date"
        : "unchecked-outside-reporting-date";

    ({ title, content } = CRQ_QUESTIONNAIRE_DIALOG_TEXTS.submit[submitKey]);
  } else if (type === "submit-without-changes") {
    const withoutChangesKey = isCheckBoxChecked ? "checked" : "unchecked";
    ({ title, content } =
      CRQ_QUESTIONNAIRE_DIALOG_TEXTS["submit-without-changes"][
        withoutChangesKey
      ]);
  } else {
    ({ title, content } = CRQ_QUESTIONNAIRE_DIALOG_TEXTS[type]);
  }

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{ margin: 8 }}
      PaperProps={{ sx: { borderRadius: 5 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          m: 2,
          gap: 2,
        }}
      >
        <Button type="button" onClick={onClose} variant="contained">
          Close
        </Button>
        <Button type="button" onClick={onContinue} variant="text">
          Continue Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
};
