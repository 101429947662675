export const subControls: Record<string, { id: string; name: string }[]> = {
  1: [
    {
      id: "1.1",
      name: "Utilize an Active Discovery Tool",
    },
    {
      id: "1.2",
      name: "Use a Passive Asset Discovery Tool",
    },
    {
      id: "1.3",
      name: "Use DHCP Logging to Update Asset Inventory",
    },
    {
      id: "1.4",
      name: "Maintain Detailed Asset Inventory",
    },
    {
      id: "1.5",
      name: "Maintain Asset Inventory Information",
    },
    {
      id: "1.6",
      name: "Address Unauthorized Assets",
    },
    {
      id: "1.7",
      name: "Deploy Port Level Access Control",
    },
    {
      id: "1.8",
      name: "Utilize Client Certificates to Authenticate Hardware Assets",
    },
  ],
  2: [
    {
      id: "2.1",
      name: "Maintain Inventory of Authorized Software",
    },
    {
      id: "2.2",
      name: "Ensure Software is Supported by Vendor",
    },
    {
      id: "2.3",
      name: "Utilize Software Inventory Tools",
    },
    {
      id: "2.4",
      name: "Track Software Inventory Information",
    },
    {
      id: "2.5",
      name: "Integrate Software and Hardware Asset Inventories",
    },
    {
      id: "2.6",
      name: "Address unapproved software",
    },
    {
      id: "2.7",
      name: "Utilize Application Whitelisting",
    },
    {
      id: "2.8",
      name: "Implement Application Whitelisting of Libraries",
    },
    {
      id: "2.9",
      name: "Implement Application Whitelisting of Scripts",
    },
    {
      id: "2.10",
      name: "Physically or Logically Segregate High Risk Applications",
    },
  ],
  3: [
    {
      id: "3.1",
      name: "Run Automated Vulnerability Scanning Tools",
    },
    {
      id: "3.2",
      name: "Perform Authenticated Vulnerability Scanning",
    },
    {
      id: "3.3",
      name: "Protect Dedicated Assessment Accounts",
    },
    {
      id: "3.4",
      name: "Deploy Automated Operating System Patch Management Tools",
    },
    {
      id: "3.5",
      name: "Deploy Automated Software Patch Management Tools",
    },
    {
      id: "3.6",
      name: "Compare Back-to-Back Vulnerability Scans",
    },
    {
      id: "3.7",
      name: "Utilize a Risk-Rating Process",
    },
  ],
  4: [
    {
      id: "4.1",
      name: "Maintain Inventory of Administrative Accounts",
    },
    {
      id: "4.2",
      name: "Change Default Passwords",
    },
    {
      id: "4.3",
      name: "Ensure the Use of Dedicated Administrative Accounts",
    },
    {
      id: "4.4",
      name: "Use Unique Passwords",
    },
    {
      id: "4.5",
      name: "Use Multi-Factor Authentication for All Administrative Access",
    },
    {
      id: "4.6",
      name: "Use Dedicated Workstations For All Administrative Tasks",
    },
    {
      id: "4.7",
      name: "Limit Access to Script Tools",
    },
    {
      id: "4.8",
      name: "Log and Alert on Changes to Administrative Group Membership",
    },
    {
      id: "4.9",
      name: "Log and Alert on Unsuccessful Administrative Account Login",
    },
  ],
  5: [
    {
      id: "5.1",
      name: "Establish Secure Configurations",
    },
    {
      id: "5.2",
      name: "Maintain Secure Images",
    },
    {
      id: "5.3",
      name: "Securely Store Master Images",
    },
    {
      id: "5.4",
      name: "Deploy System Configuration Management Tools",
    },
    {
      id: "5.5",
      name: "Implement Automated Configuration Monitoring Systems",
    },
  ],
  6: [
    {
      id: "6.1",
      name: "Utilize Three Synchronized Time Sources",
    },
    {
      id: "6.2",
      name: "Activate Audit Logging",
    },
    {
      id: "6.3",
      name: "Enable Detailed Logging",
    },
    {
      id: "6.4",
      name: "Ensure Adequate Storage for Logs",
    },
    {
      id: "6.5",
      name: "Central Log Management",
    },
    {
      id: "6.6",
      name: "Deploy SIEM or Log Analytic Tools",
    },
    {
      id: "6.7",
      name: "Regularly Review Logs",
    },
    {
      id: "6.8",
      name: "Regularly Tune SIEM",
    },
  ],
  7: [
    {
      id: "7.1",
      name: "Ensure Use of Only Fully Supported Browsers and Email Clients",
    },
    {
      id: "7.2",
      name: "Disable Unnecessary or Unauthorized Browser or Email Client Plugins",
    },
    {
      id: "7.3",
      name: "Limit Use of Scripting Languages in Web Browsers and Email Clients",
    },
    {
      id: "7.4",
      name: "Maintain and Enforce Network-Based URL Filters",
    },
    {
      id: "7.5",
      name: "Subscribe to URL-Categorization Service",
    },
    {
      id: "7.6",
      name: "Log All URL Requests",
    },
    {
      id: "7.7",
      name: "Use of DNS Filtering Services",
    },
    {
      id: "7.8",
      name: "Implement DMARC and Enable Receiver-Side Verification",
    },
    {
      id: "7.9",
      name: "Block Unnecessary File Types",
    },
    {
      id: "7.10",
      name: "Sandbox All Email Attachments",
    },
  ],
  8: [
    {
      id: "8.1",
      name: "Utilize Centrally Managed Anti-malware Software",
    },
    {
      id: "8.2",
      name: "Ensure Anti-Malware Software and Signatures Are Updated",
    },
    {
      id: "8.3",
      name: "Enable Operating System Anti-Exploitation Features/Deploy Anti-Exploit Technologies",
    },
    {
      id: "8.4",
      name: "Configure Anti-Malware Scanning of Removable Devices",
    },
    {
      id: "8.5",
      name: "Configure Devices to Not Auto-Run Content",
    },
    {
      id: "8.6",
      name: "Centralize Anti-Malware Logging",
    },
    {
      id: "8.7",
      name: "Enable DNS Query Logging",
    },
    {
      id: "8.8",
      name: "Enable Command-Line Audit Logging",
    },
  ],
  9: [
    {
      id: "9.1",
      name: "Associate Active Ports, Services, and Protocols to Asset Inventory",
    },
    {
      id: "9.2",
      name: "Ensure Only Approved Ports, Protocols, and Services Are Running",
    },
    {
      id: "9.3",
      name: "Perform Regular Automated Port Scans",
    },
    {
      id: "9.4",
      name: "Apply Host-Based Firewalls or Port-Filtering",
    },
    {
      id: "9.5",
      name: "Implement Application Firewalls",
    },
  ],
  10: [
    {
      id: "10.1",
      name: "Ensure Regular Automated Backups",
    },
    {
      id: "10.2",
      name: "Perform Complete System Backups",
    },
    {
      id: "10.3",
      name: "Test Data on Backup Media",
    },
    {
      id: "10.4",
      name: "Protect Backups",
    },
    {
      id: "10.5",
      name: "Ensure All Backups Have at Least One Offline Backup Destination",
    },
  ],
  11: [
    {
      id: "11.1",
      name: "Maintain Standard Security Configurations for Network Devices",
    },
    {
      id: "11.2",
      name: "Document Traffic Configuration Rules",
    },
    {
      id: "11.3",
      name: "Use Automated Tools to Verify Standard Device Configurations and Detect Changes",
    },
    {
      id: "11.4",
      name: "Install the Latest Stable Version of Any Security-Related Updates on All Network Devices",
    },
    {
      id: "11.5",
      name: "Manage Network Devices Using Multi-Factor Authentication and Encrypted Sessions",
    },
    {
      id: "11.6",
      name: "Use Dedicated Machines For All Network Administrative Tasks",
    },
    {
      id: "11.7",
      name: "Manage Network Infrastructure Through a Dedicated Network",
    },
  ],
  12: [
    {
      id: "12.1",
      name: "Maintain an Inventory of Network Boundaries",
    },
    {
      id: "12.2",
      name: "Scan for Unauthorized Connections Across Trusted Network Boundaries",
    },
    {
      id: "12.3",
      name: "Deny Communications With Known Malicious IP Addresses",
    },
    {
      id: "12.4",
      name: "Deny Communication Over Unauthorized Ports",
    },
    {
      id: "12.5",
      name: "Configure Monitoring Systems to Record Network Packets",
    },
    {
      id: "12.6",
      name: "Deploy Network-Based IDS Sensors",
    },
    {
      id: "12.7",
      name: "Deploy Network-Based Intrusion Prevention Systems",
    },
    {
      id: "12.8",
      name: "Deploy NetFlow Collection on Networking Boundary Devices",
    },
    {
      id: "12.9",
      name: "Deploy Application Layer Filtering Proxy Server",
    },
    {
      id: "12.10",
      name: "Decrypt Network Traffic at Proxy",
    },
    {
      id: "12.11",
      name: "Require All Remote Login to Use Multi-Factor Authentication",
    },
    {
      id: "12.12",
      name: "Manage All Devices Remotely Logging into Internal Network",
    },
  ],
  13: [
    {
      id: "13.1",
      name: "Maintain an Inventory of Sensitive Information",
    },
    {
      id: "13.2",
      name: "Remove Sensitive Data or Systems Not Regularly Accessed by Organization",
    },
    {
      id: "13.3",
      name: "Monitor and Block Unauthorized Network Traffic",
    },
    {
      id: "13.4",
      name: "Only Allow Access to Authorized Cloud Storage or Email Providers",
    },
    {
      id: "13.5",
      name: "Monitor and Detect Any Unauthorized Use of Encryption",
    },
    {
      id: "13.6",
      name: "Encrypt Mobile Device Data",
    },
    {
      id: "13.7",
      name: "Manage USB Devices",
    },
    {
      id: "13.8",
      name: "Manage System's External Removable Media's Read/Write Configurations",
    },
    {
      id: "13.9",
      name: "Encrypt Data on USB Storage Devices",
    },
  ],
  14: [
    {
      id: "14.1",
      name: "Segment the Network Based on Sensitivity",
    },
    {
      id: "14.2",
      name: "Enable Firewall Filtering Between VLANs",
    },
    {
      id: "14.3",
      name: "Disable Workstation to Workstation Communication",
    },
    {
      id: "14.4",
      name: "Encrypt All Sensitive Information in Transit",
    },
    {
      id: "14.5",
      name: "Utilize an Active Discovery Tool to Identify Sensitive Data",
    },
    {
      id: "14.6",
      name: "Protect Information Through Access Control Lists",
    },
    {
      id: "14.7",
      name: "Enforce Access Control to Data Through Automated Tools",
    },
    {
      id: "14.8",
      name: "Encrypt Sensitive Information at Rest",
    },
    {
      id: "14.9",
      name: "Enforce Detail Logging for Access or Changes to Sensitive Data",
    },
  ],
  15: [
    {
      id: "15.1",
      name: "Maintain an Inventory of Authorized Wireless Access Points",
    },
    {
      id: "15.2",
      name: "Detect Wireless Access Points Connected to the Wired Network",
    },
    {
      id: "15.3",
      name: "Use a Wireless Intrusion Detection System",
    },
    {
      id: "15.4",
      name: "Disable Wireless Access on Devices if Not Required",
    },
    {
      id: "15.5",
      name: "Limit Wireless Access on Client Devices",
    },
    {
      id: "15.6",
      name: "Disable Peer-to-Peer Wireless Network Capabilities on Wireless Clients",
    },
    {
      id: "15.7",
      name: "Leverage the Advanced Encryption Standard (AES) to Encrypt Wireless Data",
    },
    {
      id: "15.8",
      name: "Use Wireless Authentication Protocols That Require Mutual, Multi-Factor Authentication",
    },
    {
      id: "15.9",
      name: "Disable Wireless Peripheral Access of Devices",
    },
    {
      id: "15.10",
      name: "Create Separate Wireless Network for Personal and Untrusted Devices",
    },
  ],
  16: [
    {
      id: "16.1",
      name: "Maintain an Inventory of Authentication Systems",
    },
    {
      id: "16.2",
      name: "Configure Centralized Point of Authentication",
    },
    {
      id: "16.3",
      name: "Require Multi-Factor Authentication",
    },
    {
      id: "16.4",
      name: "Encrypt or Hash all Authentication Credentials",
    },
    {
      id: "16.5",
      name: "Encrypt Transmittal of Username and Authentication Credentials",
    },
    {
      id: "16.6",
      name: "Maintain an Inventory of Accounts",
    },
    {
      id: "16.7",
      name: "Establish Process for Revoking Access",
    },
    {
      id: "16.8",
      name: "Disable Any Unassociated Accounts",
    },
    {
      id: "16.9",
      name: "Disable Dormant Accounts",
    },
    {
      id: "16.10",
      name: "Ensure All Accounts Have An Expiration Date",
    },
    {
      id: "16.11",
      name: "Lock Workstation Sessions After Inactivity",
    },
    {
      id: "16.12",
      name: "Monitor Attempts to Access Deactivated Accounts",
    },
    {
      id: "16.13",
      name: "Alert on Account Login Behavior Deviation",
    },
  ],
  17: [
    {
      id: "17.1",
      name: "Perform a Skills Gap Analysis",
    },
    {
      id: "17.2",
      name: "Deliver Training to Fill the Skills Gap",
    },
    {
      id: "17.3",
      name: "Implement a Security Awareness Program",
    },
    {
      id: "17.4",
      name: "Update Awareness Content Frequently",
    },
    {
      id: "17.5",
      name: "Train Workforce on Secure Authentication",
    },
    {
      id: "17.6",
      name: "Train Workforce on Identifying Social Engineering Attacks",
    },
    {
      id: "17.7",
      name: "Train Workforce on Sensitive Data Handling",
    },
    {
      id: "17.8",
      name: "Train Workforce on Causes of Unintentional Data Exposure",
    },
    {
      id: "17.9",
      name: "Train Workforce Members on Identifying and Reporting Incidents",
    },
  ],
  18: [
    {
      id: "18.1",
      name: "Establish Secure Coding Practices",
    },
    {
      id: "18.2",
      name: "Ensure That Explicit Error Checking is Performed for All In-House Developed Software",
    },
    {
      id: "18.3",
      name: "Verify That Acquired Software is Still Supported",
    },
    {
      id: "18.4",
      name: "Only Use Up-to-Date and Trusted Third-Party Components",
    },
    {
      id: "18.5",
      name: "Use Only Standardized and Extensively Reviewed Encryption Algorithms",
    },
    {
      id: "18.6",
      name: "Ensure Software Development Personnel are Trained in Secure Coding",
    },
    {
      id: "18.7",
      name: "Apply Static and Dynamic Code Analysis Tools",
    },
    {
      id: "18.8",
      name: "Establish a Process to Accept and Address Reports of Software Vulnerabilities",
    },
    {
      id: "18.9",
      name: "Separate Production and Non-Production Systems",
    },
    {
      id: "18.10",
      name: "Deploy Web Application Firewalls",
    },
    {
      id: "18.11",
      name: "Use Standard Hardening Configuration Templates for Databases",
    },
  ],
  19: [
    {
      id: "19.1",
      name: "Document Incident Response Procedures",
    },
    {
      id: "19.2",
      name: "Assign Job Titles and Duties for Incident Response",
    },
    {
      id: "19.3",
      name: "Designate Management Personnel to Support Incident Handling",
    },
    {
      id: "19.4",
      name: "Devise Organization-wide Standards for Reporting Incidents",
    },
    {
      id: "19.5",
      name: "Maintain Contact Information For Reporting Security Incidents",
    },
    {
      id: "19.6",
      name: "Publish Information Regarding Reporting Computer Anomalies and Incidents",
    },
    {
      id: "19.7",
      name: "Conduct Periodic Incident Scenario Sessions for Personnel",
    },
    {
      id: "19.8",
      name: "Create Incident Scoring and Prioritization Schema",
    },
  ],
  20: [
    {
      id: "20.1",
      name: "Establish a Penetration Testing Program",
    },
    {
      id: "20.2",
      name: "Conduct Regular External and Internal Penetration Tests",
    },
    {
      id: "20.3",
      name: "Perform Periodic Red Team Exercises",
    },
    {
      id: "20.4",
      name: "Include Tests for Presence of Unprotected System Information and Artifacts",
    },
    {
      id: "20.5",
      name: "Create Test Bed for Elements Not Typically Tested in Production",
    },
    {
      id: "20.6",
      name: "Use Vulnerability Scanning and Penetration Testing Tools in Concert",
    },
    {
      id: "20.7",
      name: "Ensure Results from Penetration Test are Documented Using Open, Machine-readable Standards",
    },
    {
      id: "20.8",
      name: "Control and Monitor Accounts Associated with Penetration Testing",
    },
  ],
  // Add sub-controls for other CIS controls here
};
