/* eslint-disable react-refresh/only-export-components */
import { Checkbox, type CheckboxProps, FormControlLabel } from "@mui/material";
import {
  MultiTextFormField,
  MultiTextFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/fields/multi-text-form-field/multi-text-form-field";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import { type QuestionnaireResponse, US_STATES_OF_OPERATION } from "crq-types";
import { Controller, useFormContext } from "react-hook-form";

export const US_STATES_OF_OPERATION_FIELD_TYPE = "usStatesOfOperation" as const;

type USStatesOfOperationFieldProps = BaseFormFieldDefinition<
  typeof US_STATES_OF_OPERATION_FIELD_TYPE
> &
  Omit<MultiTextFormFieldDefinition, "items" | "name" | "type">;

const items = US_STATES_OF_OPERATION.map((state) => ({
  label: state,
  value: state,
}));

export const USStatesOfOperationField = (
  props: USStatesOfOperationFieldProps & AdditionalFormFieldProps
) => {
  const { control, setValue, watch } = useFormContext<QuestionnaireResponse>();
  const values = watch("usStatesOfOperation");

  const handleOnChange: CheckboxProps["onChange"] = ({ target }) => {
    setValue(
      "usStatesOfOperation",
      target.checked ? [...US_STATES_OF_OPERATION] : [],
      { shouldDirty: true }
    );
  };

  return (
    <Controller
      control={control}
      name="usStatesOfOperation"
      render={() => (
        <>
          <FormControlLabel
            label="All states and territories"
            control={
              <Checkbox
                checked={US_STATES_OF_OPERATION.every((state) =>
                  values?.includes(state)
                )}
                onChange={handleOnChange}
              />
            }
          />
          <MultiTextFormField {...props} items={items} type="multi-text" />
        </>
      )}
    />
  );
};
