import {
  Button,
  Card,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

type GeneralSettingsEventNames = "general-settings-changed";

type GeneralSettingsPayload = {
  hasActivationCheckForUpdate: boolean;
  reportingDate: string;
};

export type GeneralSettingsEventPayload = {
  hasActivationCheckForUpdate: boolean;
  reportingDate: string;
};

export const GeneralSettingsPage: PimoReactComponent<
  GeneralSettingsPayload,
  GeneralSettingsEventNames,
  GeneralSettingsEventPayload
> = ({ hasActivationCheckForUpdate, reportingDate, fireEvent }) => {
  const [formData, setFormData] = useState({
    hasActivationCheckForUpdate: hasActivationCheckForUpdate,
    reportingDate: reportingDate,
  });

  const [initialActivationCheckForUpdate, setInitialActivationCheckForUpdate] =
    useState(hasActivationCheckForUpdate);

  const [initialReportingDate, setInitialReportingDate] =
    useState(reportingDate);

  useEffect(() => {
    setFormData({
      hasActivationCheckForUpdate: hasActivationCheckForUpdate,
      reportingDate: reportingDate,
    });
    setInitialActivationCheckForUpdate(hasActivationCheckForUpdate);
    setInitialReportingDate(reportingDate);
  }, [hasActivationCheckForUpdate, reportingDate]);

  const hasChanges =
    formData.hasActivationCheckForUpdate !== initialActivationCheckForUpdate ||
    (formData.reportingDate &&
      !dayjs(formData.reportingDate).isSame(dayjs(initialReportingDate)));

  const handleSubmit = () => {
    fireEvent?.("general-settings-changed", { ...formData });
    setInitialActivationCheckForUpdate(formData.hasActivationCheckForUpdate);
    setInitialReportingDate(formData.reportingDate);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">General Settings</Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={1}
        alignItems="flex-start"
        mb={2}
        mt={4}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Setup Reporting Period
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            InputProps={{
              style: {
                height: "100%",
                borderRadius: "5px",
              },
            }}
            value={
              formData.reportingDate ? dayjs(formData.reportingDate) : null
            }
            onChange={(date) => {
              setFormData({
                ...formData,
                reportingDate: date?.format("YYYY-MM-DD") ?? "",
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" mb={2} mt={5}>
        <Typography
          sx={{
            ml: 2,
            fontWeight: "bold",
          }}
        >
          Activate confirmation checkbox for updates
        </Typography>
        <Switch
          checked={formData.hasActivationCheckForUpdate}
          onChange={(e) =>
            setFormData({
              ...formData,
              hasActivationCheckForUpdate: e.target.checked,
            })
          }
        />
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "auto" }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ ml: 2 }}
          disabled={!hasChanges}
        >
          SAVE
        </Button>
      </Stack>
    </Card>
  );
};
