import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "../form-field";

export const DROPDOWN_FORM_FIELD_TYPE = "dropdown" as const;

export type DropdownFormFieldDefinition = BaseFormFieldDefinition<
  typeof DROPDOWN_FORM_FIELD_TYPE
> & {
  type: typeof DROPDOWN_FORM_FIELD_TYPE;
  items: {
    value: string;
    label: string;
  }[];
  multiSelect?: boolean;
};

export function DropdownFormField({
  disabled,
  errorMessage,
  items,
  multiSelect,
  name,
  required,
}: DropdownFormFieldDefinition & AdditionalFormFieldProps) {
  const { control } = useFormContext();

  const renderSelectedValue = (selected: string | string[]) => {
    // Display only 3 selected items and show the rest as "+ n more"
    const displayLimit = 3;

    if (multiSelect) {
      const selectedValues = selected as string[];
      const selectedLabels = selectedValues
        .map((value) => items.find((i) => i.value === value)?.label ?? value)
        .filter(Boolean);
      const displayText =
        selectedLabels.length > displayLimit
          ? `${selectedLabels.slice(0, displayLimit).join(", ")} + ${
              selectedLabels.length - displayLimit
            } more`
          : selectedLabels.join(", ");
      return displayText;
    }
    const selectedValue = selected as string;
    return items.find((i) => i.value === selectedValue)?.label ?? selectedValue;
  };

  return (
    <FormControl fullWidth error={!!errorMessage}>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field }) => (
          <Select
            {...field}
            disabled={disabled}
            error={!!errorMessage}
            multiple={multiSelect}
            renderValue={renderSelectedValue}
            value={field.value ?? (multiSelect ? [] : "")}
          >
            {items.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {multiSelect && (
                  <Checkbox
                    checked={
                      !!(field.value as string[])?.includes(option.value)
                    }
                  />
                )}
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
