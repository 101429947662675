import { App, Route } from "@pimo/pimo-app-builder";
import {
  CustomFormFields,
  FormNavigationDirection,
  PimoFormDialog,
} from "@pimo/pimo-components";
import {
  CIS_CONTROL_RATINGS,
  COUNTRIES_OF_OPERATION,
  CURRENCIES,
  INDUSTRIES_OF_OPERATION,
  NUMBER_OF_EMPLOYEES,
  QuestionnaireResponse,
  UPDATE_STATUS,
} from "crq-types";
import { getUpdateStatus } from "crq-utils";
import { generatePath } from "react-router-dom";

import { CRQAppState } from "../app";
import { CRQFormFieldInfoSlot } from "../components/crq-form-field-info-slot";
import { CRQQuestionnaireDialog } from "../components/crq-questionnaire-dialog";
import { ErrorDialog } from "../components/dialog-error";
import { APP_ROUTES } from "../constants";
import {
  fetchOE,
  fetchQuestionnaireForOE,
  saveQuestionnaireForOE,
} from "../helpers/fetch-helpers";
import { fetchGlobalSetting } from "../helpers/global-setting";
import {
  CIS_CONTROL_ASSESSMENT_FIELD_TYPE,
  CISControlAssessmentField,
} from "./custom-form-fields/cis-control-assessment-field";
import {
  US_STATES_OF_OPERATION_FIELD_TYPE,
  USStatesOfOperationField,
} from "./custom-form-fields/us-states-of-operation-field";
import { FORM_FIELD_INFO_TEXT } from "./form-field-info-text";
import { validateFormData } from "./validate-form-data";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CUSTOM_FIELDS = {
  [CIS_CONTROL_ASSESSMENT_FIELD_TYPE]: CISControlAssessmentField,
  [US_STATES_OF_OPERATION_FIELD_TYPE]: USStatesOfOperationField,
} as const satisfies
  | CustomFormFields<typeof CIS_CONTROL_ASSESSMENT_FIELD_TYPE>
  | CustomFormFields<typeof US_STATES_OF_OPERATION_FIELD_TYPE>;

export function buildFormRoute(
  app: App<CRQAppState>,
  parentRoute: Route<"id">
) {
  const dialog = app.createView({
    name: "Edit",
  });

  const pimoForm = new PimoFormDialog<
    QuestionnaireResponse & { navigationDirection?: FormNavigationDirection },
    typeof CUSTOM_FIELDS
  >();

  pimoForm.registerFormField("cisControlAssessment", CISControlAssessmentField);
  pimoForm.registerFormField("usStatesOfOperation", USStatesOfOperationField);

  pimoForm.setTitle("Cyber Risk Quantification");

  const companyInputTab = pimoForm.addFormTab({
    label: "Company Data",
    id: "companyData",
  });

  const assetGroupsTab = pimoForm.addFormTab({
    label: "Asset Groups",
    id: "assetGroups",
  });

  const employeeEndpointsTab = assetGroupsTab.addChildTab({
    label: "Employee Endpoints",
    id: "employeeEndpoints",
  });

  const infrastructureTab = assetGroupsTab.addChildTab({
    label: "Infrastructure",
    id: "infrastructure",
  });

  const cloudTab = assetGroupsTab.addChildTab({
    label: "Cloud",
    id: "cloud",
  });

  const securityProfilesTab = pimoForm.addFormTab({
    label: "Security Profiles",
    id: "securityProfiles",
  });

  companyInputTab.addLayoutElement({
    type: "form-tab-title",
    label: "Company Data",
    grayBoxText: "This section refers to your company's general data.",
  });

  companyInputTab.addLayoutElement({
    type: "subheadline",
    label: "Profile",
  });

  companyInputTab.addFormField({
    label: "Company Name",
    type: "text",
    name: "oeName",
    disabled: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["oeName"]} />,
  });

  companyInputTab.addFormField({
    label: "Contact Person",
    type: "text",
    name: "contact",
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["contact"]} />,
  });

  companyInputTab.addFormField({
    label: "URLs",
    name: "urls",
    type: "multi-text",
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["urls"]} />,
    entryLabel: "URL",
  });

  companyInputTab.addFormField({
    label: "Annual Revenue",
    name: "annualRevenue",
    type: "number",
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["annualRevenue"]} />
    ),
  });

  companyInputTab.addFormField({
    label: "Number of Employees",
    name: "numberOfEmployees",
    type: "dropdown",
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["numberOfEmployees"]} />
    ),
    items: NUMBER_OF_EMPLOYEES.map((nr) => ({
      label: nr.substring(1),
      value: nr,
    })),
  });

  companyInputTab.addLayoutElement({
    type: "subheadline",
    label: "Segmentation",
  });

  companyInputTab.addFormField({
    label: "Industries of Operation",
    name: "industriesOfOperation",
    type: "multi-text",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["industriesOfOperation"]}
      />
    ),
    entryLabel: "industriesOfOperation",
    items: INDUSTRIES_OF_OPERATION.map((industry) => ({
      label: industry,
      value: industry,
    })),
    showMoreIndicator: true,
  });

  companyInputTab.addFormField({
    label: "Countries of Operation",
    name: "countriesOfOperation",
    type: "multi-text",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["countriesOfOperation"]}
      />
    ),
    items: COUNTRIES_OF_OPERATION.map((country) => ({
      label: country,
      value: country,
    })),
    showMoreIndicator: true,
  });

  companyInputTab.addFormField({
    label: "US States of Operation",
    name: "usStatesOfOperation",
    type: "usStatesOfOperation",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["usStatesOfOperation"]}
      />
    ),
    showmoreIndicator: true,
    isVisibile: (data) =>
      (data as QuestionnaireResponse)?.countriesOfOperation?.includes(
        "United States"
      ),
  });

  companyInputTab.addLayoutElement({
    type: "subheadline",
    label: "Regulations",
  });

  companyInputTab.addLayoutElement({
    label: "What regulations does your entity need to comply with?",
    description:
      "Please select the specific regulation if it applies at least for one legal sub-entity of your Allianz entity.",
    type: "form-label",
  });

  companyInputTab.addFormField({
    label: "US Federal",
    type: "checkbox",
    name: "complyWithUSFederal",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "US State",
    type: "checkbox",
    name: "complyWithUSState",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "GDPR",
    type: "checkbox",
    name: "complyWithGDPR",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "PCI",
    type: "checkbox",
    name: "complyWithPCI",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "Other",
    type: "checkbox",
    name: "complyWithOther",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addLayoutElement({
    label: "Select all security certifications that your entity has obtained.",
    description:
      "The certification needs to be applied for the whole entity as such, if this is only partially fulfilled, please do not select the specific certification.",
    type: "form-label",
  });

  companyInputTab.addFormField({
    label: "SOC II Type 2",
    type: "checkbox",
    name: "obtainedCertificationForSOC2",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "ISO 27001",
    type: "checkbox",
    name: "obtainedCertificationForISO27001",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "PCI/DSS",
    type: "checkbox",
    name: "obtainedCertificationForPCIDSS",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "NIST 800-53",
    type: "checkbox",
    name: "obtainedCertificationForNIST80053",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "NIST CSF",
    type: "checkbox",
    name: "obtainedCertificationForNISTCSF",
    uiOptions: {
      fieldSize: { xs: 12 / 5 },
    },
  });

  companyInputTab.addFormField({
    label: "Comments (optional)",
    type: "text",
    name: "companyDataComment",
    multiline: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["comments"]} />,
  });

  employeeEndpointsTab.addLayoutElement({
    type: "form-tab-title",
    label: "Employee Endpoints",
    grayBoxText:
      "The following questions referring your employees' endpoints, including employees' laptops, desktops, etc.",
  });

  employeeEndpointsTab.addFormField({
    label: "How many employees' endpoints are in your organization?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["numberOfEmployeeEndpoints"]}
      />
    ),
    name: "numberOfEmployeeEndpoints",
    type: "number",
  });

  employeeEndpointsTab.addLayoutElement({
    label:
      "Can the following data be accessed from employees' endpoints in your organization?",
    description:
      "A 'Yes' or 'No' answer for whether the employees' endpoints have access to each of the different data types (PCI, PII, PHI) maintained by your organization.",
    type: "form-label",
  });

  employeeEndpointsTab.addFormField({
    label: "PCI",
    name: "canEmployeesAccessPCIData",
    type: "checkbox",

    uiOptions: {
      fieldSize: { xs: 4 },
    },
  });

  employeeEndpointsTab.addFormField({
    label: "PII",
    name: "canEmployeesAccessPIIData",
    type: "checkbox",
    uiOptions: {
      fieldSize: { xs: 4 },
    },
  });

  employeeEndpointsTab.addFormField({
    label: "PHI",
    name: "canEmployeesAccessPHIData",
    type: "checkbox",
    uiOptions: {
      fieldSize: { xs: 4 },
    },
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["canEmployeesAccessPHIData"]}
      />
    ),
  });

  employeeEndpointsTab.addFormField({
    label:
      "What percentage of your productivity relies on employees' endpoints?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["percProductivityEmployees"]}
      />
    ),
    startAdornment: "percentage",
    type: "number",
    name: "percProductivityEmployees",
  });

  employeeEndpointsTab.addFormField({
    label: "What percentage of your income relies on employee's endpoints?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["percIncomeEmployees"]}
      />
    ),
    startAdornment: "percentage",
    type: "number",
    name: "percIncomeEmployees",
  });

  employeeEndpointsTab.addFormField({
    label: "Comments (optional)",
    type: "text",
    name: "employeeEndpointsComment",
    multiline: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["comments"]} />,
  });

  infrastructureTab.addLayoutElement({
    type: "form-tab-title",
    label: "Infrastructure",
    grayBoxText:
      "The following questions refer to your entity's on-premises infrastructure such as data centers and servers.",
  });

  infrastructureTab.addLayoutElement({
    label: "How many data records are stored in your infrastructure?",
    type: "form-label",
  });

  infrastructureTab.addFormField({
    label: "PCI",
    uiOptions: {
      skinnyLabel: true,
      fieldSize: { xs: 4 },
    },
    name: "nrPCIOnPremise",
    type: "number",
  });

  infrastructureTab.addFormField({
    label: "PII",
    uiOptions: {
      fieldSize: { xs: 4 },
      skinnyLabel: true,
    },
    name: "nrPIIOnPremise",
    type: "number",
  });

  infrastructureTab.addFormField({
    label: "PHI",
    uiOptions: {
      skinnyLabel: true,
      fieldSize: { xs: 4 },
    },
    name: "nrPHIOnPremise",
    type: "number",
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["nrPHIOnPremise"]} />
    ),
  });

  infrastructureTab.addFormField({
    label:
      "What is the maximum number of records stored together in your infrastructure?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["maxRecordsOnPremise"]}
      />
    ),
    type: "number",
    name: "maxRecordsOnPremise",
  });

  infrastructureTab.addFormField({
    label: "What percentage of your productivity relies on the infrastructure?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["percProductivityOnPremise"]}
      />
    ),
    type: "number",
    startAdornment: "percentage",
    name: "percProductivityOnPremise",
  });

  infrastructureTab.addFormField({
    label: "What percentage of your income relies on the infrastructure?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["percIncomeOnPremise"]}
      />
    ),
    type: "number",
    name: "percIncomeOnPremise",
    startAdornment: "percentage",
  });

  infrastructureTab.addFormField({
    label: "Comments (optional)",
    type: "text",
    name: "infrastructureComment",
    multiline: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["comments"]} />,
  });

  cloudTab.addLayoutElement({
    type: "form-tab-title",
    label: "Cloud",
    grayBoxText:
      "The following questions refer to your entity's different PaaS and SaaS vendors and products and the data they store.",
  });

  cloudTab.addLayoutElement({
    label: "How many data records are stored in your cloud?",
    type: "form-label",
  });

  cloudTab.addFormField({
    label: "PCI",
    name: "nrPCICloud",
    type: "number",
    uiOptions: {
      fieldSize: { xs: 4 },
      skinnyLabel: true,
    },
  });

  cloudTab.addFormField({
    label: "PII",
    name: "nrPIICloud",
    type: "number",
    uiOptions: {
      fieldSize: { xs: 4 },
      skinnyLabel: true,
    },
  });

  cloudTab.addFormField({
    label: "PHI",
    name: "nrPHICloud",
    type: "number",
    uiOptions: {
      fieldSize: { xs: 4 },
      skinnyLabel: true,
    },
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["nrPHICloud"]} />
    ),
  });

  cloudTab.addFormField({
    label:
      "What is the maximum number of records stored together in your cloud?",
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["maxRecordsCloud"]} />
    ),
    type: "number",
    name: "maxRecordsCloud",
  });

  cloudTab.addFormField({
    label: "What percentage of your productivity relies on the cloud?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["percProductivityCloud"]}
      />
    ),
    type: "number",
    name: "percProductivityCloud",
    startAdornment: "percentage",
  });

  cloudTab.addFormField({
    label: "What percentage of your income relies on the cloud?",
    infoSlot: (
      <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["percIncomeCloud"]} />
    ),
    type: "number",
    name: "percIncomeCloud",
    startAdornment: "percentage",
  });

  cloudTab.addFormField({
    label: "Comments (optional)",
    type: "text",
    name: "cloudComment",
    multiline: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["comments"]} />,
  });

  securityProfilesTab.addLayoutElement({
    type: "form-tab-title",
    label: "Security",
    grayBoxText:
      "The following questions refer to your entity's security posture.",
  });

  securityProfilesTab.addFormField({
    label:
      "In hours, what outage duration will cause a material impact on your entity?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["outageDurationMatImpact"]}
      />
    ),
    type: "number",
    name: "outageDurationMatImpact",
  });

  securityProfilesTab.addFormField({
    label:
      "In hours, how long does it typically take to restore your critical business operations following a network interruption?",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["networkInterruptionRestorationTime"]}
      />
    ),
    type: "number",
    name: "networkInterruptionRestorationTime",
  });

  securityProfilesTab.addLayoutElement({
    type: "subheadline",
    label: "CIS Controls",
    infoSlot: (
      <CRQFormFieldInfoSlot
        text={FORM_FIELD_INFO_TEXT["CISControl"]}
        img="/cis-control.png"
      />
    ),
  });

  securityProfilesTab.addLayoutElement({
    type: "subheadline",
    label: "Basic Controls",
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 1 - Inventory and Control of Hardware Assets",
    name: "CISControl1",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-1.png"
        text={FORM_FIELD_INFO_TEXT["CISControl1"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 2 - Inventory and Control of Software Assets",
    name: "CISControl2",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-2.png"
        text={FORM_FIELD_INFO_TEXT["CISControl2"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 3 - Continuous Vulnerability Management",
    name: "CISControl3",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-3.png"
        text={FORM_FIELD_INFO_TEXT["CISControl3"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 4 - Controlled Use of Administrative Privileges",
    name: "CISControl4",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-4.png"
        text={FORM_FIELD_INFO_TEXT["CISControl4"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label:
      "CIS Control 5 - Secure Configuration for Hardware and Software on Mobile Devices, Laptops, Workstations and Servers",
    name: "CISControl5",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-5.png"
        text={FORM_FIELD_INFO_TEXT["CISControl5"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 6 - Maintenance, Monitoring and Analysis of Audit Logs",
    name: "CISControl6",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-6.png"
        text={FORM_FIELD_INFO_TEXT["CISControl6"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addLayoutElement({
    type: "subheadline",
    label: "Foundational Controls",
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 7 - Email and Web Browser Protections",
    name: "CISControl7",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-7.png"
        text={FORM_FIELD_INFO_TEXT["CISControl7"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 8 - Malware Defenses",
    name: "CISControl8",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-8.png"
        text={FORM_FIELD_INFO_TEXT["CISControl8"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label:
      "CIS Control 9 - Limitation and Control of Network Ports, Protocols, and Services",
    name: "CISControl9",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-9.png"
        text={FORM_FIELD_INFO_TEXT["CISControl9"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 10 - Data Recovery Capabilities",
    name: "CISControl10",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-10.png"
        text={FORM_FIELD_INFO_TEXT["CISControl10"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label:
      "CIS Control 11 - Secure Configuration for Network Devices, such as Firewalls, Routers and Switches",
    name: "CISControl11",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-11.png"
        text={FORM_FIELD_INFO_TEXT["CISControl11"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 12 - Boundary Defense",
    name: "CISControl12",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-12.png"
        text={FORM_FIELD_INFO_TEXT["CISControl12"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 13 - Data Protection",
    name: "CISControl13",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-13.png"
        text={FORM_FIELD_INFO_TEXT["CISControl13"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 14 - Controlled Access Based on the Need to Know",
    name: "CISControl14",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-14.png"
        text={FORM_FIELD_INFO_TEXT["CISControl14"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 15 - Wireless Access Control",
    name: "CISControl15",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-15.png"
        text={FORM_FIELD_INFO_TEXT["CISControl15"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 16 - Account Monitoring and Control",
    name: "CISControl16",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-16.png"
        text={FORM_FIELD_INFO_TEXT["CISControl16"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addLayoutElement({
    type: "subheadline",
    label: "Organizational Controls",
  });

  securityProfilesTab.addFormField({
    label:
      "CIS Control 17 - Implement a Security Awareness and Training Program",
    name: "CISControl17",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-17.png"
        text={FORM_FIELD_INFO_TEXT["CISControl17"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 18 - Application Software Security",
    name: "CISControl18",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-18.png"
        text={FORM_FIELD_INFO_TEXT["CISControl18"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 19 - Incident Response and Management",
    name: "CISControl19",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-19.png"
        text={FORM_FIELD_INFO_TEXT["CISControl19"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "CIS Control 20 - Penetration Tests and Red Team Exercises",
    name: "CISControl20",
    infoSlot: (
      <CRQFormFieldInfoSlot
        img="/cis-control-20.png"
        text={FORM_FIELD_INFO_TEXT["CISControl20"]}
      />
    ),
    type: "cisControlAssessment",
    items: CIS_CONTROL_RATINGS.map((rating) => ({
      label: rating,
      value: rating,
    })),
  });

  securityProfilesTab.addFormField({
    label: "Comments (optional)",
    type: "text",
    name: "securityProfilesComment",
    multiline: true,
    infoSlot: <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["comments"]} />,
  });

  const form = dialog.addComponent({
    component: pimoForm,
  });

  function close() {
    const { currentOE } = app.getAppState();
    if (currentOE?.id == null) {
      return;
    }

    app.navigate(
      generatePath(APP_ROUTES.report, {
        id: String(currentOE?.id),
      })
    );
  }

  async function submit(payload?: QuestionnaireResponse) {
    const state = app.getAppState();
    const { currentOE, currentQuestionnaire } = state;

    if (currentOE?.id == null || !payload) {
      return;
    }

    app.patchAppState({
      isOEInsideReportingDate:
        getUpdateStatus(
          state.program?.reportingDate,
          state.currentQuestionnaire?.questionnaireSubmittedAt
        ) === UPDATE_STATUS[0],
      currentQuestionnaire: {
        ...payload,
        isDataUpToDate: state.currentQuestionnaire?.isDataUpToDate,
        oeName: currentQuestionnaire?.oeName ?? "",
        questionnaireSubmittedAt: new Date().toISOString(),
      },
    });

    await saveQuestionnaireForOE(currentOE?.id, {
      ...payload,
      isDataUpToDate: state.currentQuestionnaire?.isDataUpToDate,
    });

    pimoForm?.getFormMethods()?.reset(payload);
  }

  form.on("cancel", () => {
    app.patchAppState({
      isOEInsideReportingDate: undefined,
    });
    close();
  });

  form.on("show-alert-dialog", () => {
    app.patchAppState({
      showQuestionnairePopUp: true,
    });
  });

  form.on("confirm-up-to-date", () => {
    const updatedIsDataUpToDate =
      !app.getAppState().currentQuestionnaire?.isDataUpToDate;

    app.patchAppState({
      currentQuestionnaire: {
        ...(app.getAppState().currentQuestionnaire as QuestionnaireResponse),
        isDataUpToDate: updatedIsDataUpToDate,
      },
    });
  });

  form.on("cancel-with-changes", () => {
    app.patchAppState({
      showQuestionnaireDialog: true,
      questionnaireDialogType: "cancel-with-changes",
    });
  });

  form.on("submit", async ({ payload }) => {
    const { userProfile, currentOE } = app.getAppState();
    if (payload) {
      await submit({
        ...payload,
        questionnaireSubmittedAt: new Date().toISOString(),
        questionnaireSubmittedBy: userProfile?.name,
      });
    }

    app.patchAppState({
      showQuestionnaireDialog: !payload?.navigationDirection,
      questionnaireDialogType:
        currentOE?.active !== false ? "submit" : undefined,
    });
  });

  form.on("submit-without-changes", async ({ payload }) => {
    if (payload) {
      await submit(payload);
    }

    app.patchAppState({
      showQuestionnaireDialog: !payload?.navigationDirection,
      questionnaireDialogType: "submit-without-changes",
    });
  });

  const onContinue = () => {
    app.patchAppState({
      showQuestionnairePopUp: false,
    });
    const formData = pimoForm?.getFormMethods()?.getValues();
    const lastActionAttempted = pimoForm.getLastActionAttempted();

    if (formData) {
      form.fireEvent?.("submit", formData);
    }

    if (lastActionAttempted === "save-and-next") {
      pimoForm?.navigate("next");
    } else if (lastActionAttempted === "save-and-close") {
      close();
    }
  };

  const onResolve = () => {
    app.patchAppState({
      showQuestionnairePopUp: false,
    });
  };

  form.mapState((state) => {
    const draftMode = state.currentOE?.active === false;

    return {
      ...(state.currentQuestionnaire as QuestionnaireResponse),
      saveOnAction: true,
      validationTarget: "current-tab",

      validateFormData,
      isCheckBoxChecked: state.currentQuestionnaire?.isDataUpToDate ?? false,
      addCheckBoxToForm: state.settings.hasActivationCheckForUpdate,
      alertDialog: draftMode
        ? () => (
            <ErrorDialog
              onContinue={onContinue}
              onResolve={onResolve}
              open={state.showQuestionnairePopUp ?? false}
            />
          )
        : undefined,
    };
  });

  const confirmationDialog = dialog.addComponent({
    component: CRQQuestionnaireDialog,
  });

  confirmationDialog.mapVisibility(
    ({ showQuestionnaireDialog, questionnaireDialogType }) => {
      return showQuestionnaireDialog && questionnaireDialogType != null;
    }
  );

  confirmationDialog.mapState(({ questionnaireDialogType }) => {
    const state = app.getAppState();

    return {
      type: questionnaireDialogType,
      // if the user has deactivated hasActivationCheckForUpdate, automatically the checkbox is set to false
      isCheckBoxChecked: state.settings.hasActivationCheckForUpdate
        ? (state.currentQuestionnaire?.isDataUpToDate ?? false)
        : false,
      isOEInsideReportingDate: state.isOEInsideReportingDate ?? false,
    };
  });

  function closeConfirmationDialog() {
    app.patchAppState({
      questionnaireDialogType: undefined,
      showQuestionnaireDialog: false,
      isOEInsideReportingDate: undefined,
    });
  }

  confirmationDialog.on("close", () => {
    closeConfirmationDialog();
    close();
  });

  confirmationDialog.on("continue", () => {
    closeConfirmationDialog();
  });

  const route = parentRoute.createChildRoute({
    path: APP_ROUTES.editQuestionnaire,
    view: dialog,
    isOverlayChild: true,
  });

  route.on("load", async ({ payload }) => {
    const id = payload?.parameters?.id;

    if (id == null) {
      return;
    }

    const [currentOE, currentQuestionnaire, globalSetting] = await Promise.all([
      fetchOE(Number(id)),
      fetchQuestionnaireForOE(Number(id)),
      fetchGlobalSetting(),
    ]);

    const { settings } = app.getAppState();

    pimoForm.setTitle(
      `Cyber Risk Quantification ${currentOE?.active === false ? " (Draft)" : ""}`
    );

    if (companyInputTab.hasFormField("currency")) {
      companyInputTab.removeFormField("currency");
    }

    companyInputTab.addFormField({
      label: "Currency",
      name: "currency",
      disabled: currentOE?.active === null || currentOE?.active === true,
      type: "dropdown",
      infoSlot: (
        <CRQFormFieldInfoSlot text={FORM_FIELD_INFO_TEXT["currency"]} />
      ),
      items: CURRENCIES.map((currency) => ({
        label: currency,
        value: currency,
      })),
    });

    app.patchAppState({
      currentOE,
      currentQuestionnaire,
      settings: {
        ...settings,
        hasActivationCheckForUpdate:
          globalSetting?.hasActivationCheckForUpdate ?? true,
      },
    });
  });
}
